import { PasswordResetForm } from './components/password-reset-form'
import { render } from '../../utils/react'
import { defineCustomElement } from '../../utils/webcomponents'

class PasswordReset extends HTMLElement {
    get token(): string {
        return this.getAttribute('token') ?? ''
    }

    connectedCallback() {
        const uidb64 = window.location.pathname.split('/')[3] ?? ''
        const mountPoint = document.createElement('div')

        this.append(mountPoint)

        render({
            component: <PasswordResetForm token={this.token} uidb64={uidb64} />,
            container: mountPoint,
        })
    }
}

defineCustomElement('adverity-password-reset', PasswordReset)
