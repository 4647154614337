import { useMutation } from '@tanstack/react-query'

import api from '../../api'
import { API_URLS } from '../../utils/constants'

type ChangePasswordPayload = {
    password: string
    uidb64: string
    token: string
}

export const useChangePasswordMutation = (
    passwordPayload: ChangePasswordPayload,
    { onError }: { onError?: (error: unknown) => void },
) => {
    const { uidb64, token, password } = passwordPayload

    return useMutation({
        mutationFn: () => api.post(API_URLS.resetPassword({ uidb64, token }), { password }),
        onSuccess: () => {
            window.location.assign('/login/')
        },
        onError,
    })
}
